import React from 'react';

import Layout from '../components/Layout';

export default function VeranstaltungenUndEinsaetze() {
	return (
		<Layout title="Veranstaltungen und Einsätze">
			<h2>Veranstaltungen und Einsätze</h2>
			<p>
				<ul>
					<li>
						<a
							href="https://www.brk-bgl.de/aktuell/presse/meldung/rotes-kreuz-und-malteser-versorgen-14-verletzte-und-erkrankte-beim-reichenhaller-stadtfest.html"
							target="_blank"
						>
							01.07.2023: Sanit&auml;tsdienst beim Reichenhaller Stadtfest
						</a>
					</li>
					<li>
						<a
							href="https://www.brk-bgl.de/aktuell/presse/meldung/auffahrunfall-mit-sieben-beteiligten-insassen-auf-der-a8-bei-anger.html"
							target="_blank"
						>
							17.09.2022: Auffahrunfall auf der A8 bei Anger
						</a>
					</li>
					<li>
						<a
							href="https://www.brk-bgl.de/aktuell/presse/meldung/ehrenamtliche-der-brk-bereitschaften-ergaenzen-mit-ihren-notfallkrankenwagen-den-regulaeren-rettungsdienst.html"
							target="_blank"
						>
							20.07.2022: Unterstützung Rettungsdienst
						</a>
					</li>
					<li>
						<a
							href="https://www.brk-bgl.de/aktuell/presse/meldung/blitz-schlaegt-in-pidinger-wohnhaus-ein-und-setzt-dachstuhl-in-brand-zwei-bewohner-durch-elektrische-entladung-und-knall-verletzt.html"
							target="_blank"
						>
							27.06.2022: Absicherung Feuerwehreinsatz in Piding
						</a>
					</li>
					<li>
						<a
							href="https://www.brk-bgl.de/aktuell/presse/meldung/acht-patienten-versorgt-39-ehrenamtliche-von-bergwacht-und-brk-bereitschaft-sichern-alpenstadt-city-trail-in-bad-reichenhall-und-am-hochstaufen-ab.html"
							target="_blank"
						>
							29.05.2022: Absicherung &bdquo;Alpenstadt City & Trail&ldquo;
						</a>
					</li>
					<li>
						<a
							href="https://www.brk-bgl.de/aktuell/presse/meldung/heimisches-rotes-kreuz-unterstuetzt-nachbar-landkreis-bei-busunfall-in-inzell-mit-52-verletzten.html"
							target="_blank"
						>
							26.02.2022: Busunfall in Inzell
						</a>
					</li>
				</ul>
			</p>
		</Layout>
	);
}
